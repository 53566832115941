import i18n from '@/plugins/i18n'
import { regexToPatternString } from './Regex'
import FormRule from '@/models/forms/FormRule'

export const RecipientFormRules: Dictionary<Array<FormRule>> = {
  corp_pid: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('corps_id'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'change' }
  ],
  country: [
    { required: true, message: `${i18n.t('commons.select')}` }
  ],
  currency: [
    { required: true, message: `${i18n.t('commons.select')}` }
  ],
  first_name: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('name_first'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  middle_name: [
    { pattern: regexToPatternString('name_middle'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  last_name: [
    { pattern: regexToPatternString('name_last'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  corp_name: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('numberEnglish'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  phone_code: [
    { required: true, message: `${i18n.t('commons.select')}` }
  ],
  phone_number: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('phone'), message: `${i18n.t('commons.type_only_number')}` }
  ],
  master_code: [
    { required: true, message: `${i18n.t('commons.select')}` }
  ],
  bank_routing_number: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('routing_number'), message: `${i18n.t('commons.type_only_number')}` }
  ],
  bank_account_number: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('account_number'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  postal_code: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('numberEnglish'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  city: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('numberEnglish'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  line1: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('address_line1'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  bank_branch: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('numberEnglish'), message: `${i18n.t('commons.check_write_format')}` }
  ],
  bank_sort_code: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('sort_code'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  bank_ifsc: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('ifsc'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  debit_card_number: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('card_number'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  bank_transit_code: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('transit_code'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  bank_iban: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('numberEnglish'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  bank_bsb_code: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('numberEnglish'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  bank_swift: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('swift'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  id_card_number: [
    { required: true, message: `${i18n.t('commons.required_type')}` },
    { pattern: regexToPatternString('numberEnglish'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
  corresponding_bank_swift: [
    { pattern: regexToPatternString('swift'), message: `${i18n.t('commons.check_write_format')}`, trigger: 'blur' }
  ],
}
