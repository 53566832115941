import { IndividualRecipientToRegister } from '@/components/recipient/model/IndividualRecipienttoRegister'
import { IGetMasterCodeOption } from '@/entity/validator/data/RecipientRules'
import { ReceiveMethodEnum } from '@/enums'
import i18n from '@/plugins/i18n'
import FormItemsOption from '@/models/forms/FormItemsOption'
import { RecipientSkeletonFields } from '@/gateway/recipient/model/RecipientSkeletonFields'
import { IIndividualRecipientUseCase, IndividualRecipientUseCase } from '@/usecase/recipient/IndividualRecipientUseCase'
import { IRecipient } from '@/usecase/recipient/model/IRecipient'
import { IRecipientUseCase, RecipientUseCase } from '@/usecase/recipient/RecipientUseCase'
import FormRule from '@/models/forms/FormRule'
import { CreateRecipientsParam } from '@/gateway/recipient/CreateRecipients'
import { IdCardTypesByCountry } from '@/data/IdCardTypesByCountry'
import { IdCardTypeEnum } from '@/enums/IdCardTypeEnum'
import { usdAnyWhereMasterCodes } from '@/data/masterCodes/UsdAnywhereMasterCodes'
import UnsupportedBankCountriesForUSD from '@/data/UnsupportedBankCountriesForUSD'

export interface IIndividualRecipientModalPresentation {
  formField: IndividualRecipientToRegister
  formOptions: Array<Array<FormItemsOption>>
  formRules: Dictionary<Array<FormRule>>
  receivableCountries: Array<{ label: string; value: string | number | boolean }>
  availableCurrencies: Dictionary<Array<string>>
  recipientSkeletonFields: Array<RecipientSkeletonFields>

  isEveryFieldFilled: boolean

  onChangeNation(): void
  onChangeCurrency(): void
  onChangeIndividual(): void
  onChangeRemittanceMethod(): void
  formValidate(formName: any): Promise<boolean>
  refineFieldToParam(): IRecipient
  createRecipient(): Promise<boolean>
  convertCountriesToOptions(
    countries: Array<{ iso: string; name: string }>
  ): Array<{ label: string; value: string | number | boolean }>
  convertMasterCodesToOptions(
    masterCodes: Dictionary<number>
  ): Array<{ label: string; value: string | number | boolean }>
  convertIdCardTypesToOptions(
    idCardTypes: Array<IdCardTypeEnum>
  ): Array<{ label: string; value: string | number | boolean }>
  setReceivableCountries(): Promise<void>
  setFieldWithSkeleton(country: string, remittanceMethodType: keyof typeof ReceiveMethodEnum): void
  setMasterCodesOptions(): void
  setOptions(): void
  init(): Promise<void>
}

export class IndividualRecipientModalPresentation implements IIndividualRecipientModalPresentation {
  private recipientUseCase: IRecipientUseCase = new RecipientUseCase()
  private individualRecipientUseCase: IIndividualRecipientUseCase = new IndividualRecipientUseCase()

  private corpId!: string

  private countryMasterCodesWithCurrency: Dictionary<Dictionary<Dictionary<number>>>
  private countryWithCurrencyDivision!: Array<string>

  private phoneCodes: Array<{ label: string; value: number; key: string }> =
    this.individualRecipientUseCase.CountryPhoneCodes.map(country => {
      const phoneCodesLabel: string = `${
        localStorage.getItem('locale') === 'ko' ? country.name_korean : country.name
      } (+${country.phone_code})`
      return { label: phoneCodesLabel, value: country.phone_code, key: country.name }
    })

  /**
   * IndividualRecipientModalPresentation의 생성자.
   * individualRecipientUseCase를 통해 countryMasterCodesWithCurrency를 가져와 countryWithCurrencyDivision에 나라 이름 배열을 할당한다.
   */
  constructor() {
    this.countryMasterCodesWithCurrency = this.individualRecipientUseCase.countryMasterCodesWithCurrency
    this.countryWithCurrencyDivision = Object.keys(this.countryMasterCodesWithCurrency)
  }

  public formField: IndividualRecipientToRegister = {
    corp_pid: '',
    country: '',
    currency: '',
    is_individual: true,
    first_name: '',
    middle_name: '',
    last_name: '',
    corp_name: '',
    phone_code: '82',
    phone_number: '',
    remittance_method_type: 'BANK',
    bank_swift: '',
    bank_iban: '',
    bank_routing_number: '',
    bank_sort_code: '',
    bank_ifsc: '',
    bank_transit_code: '',
    bank_bsb_code: '',
    debit_card_number: '',
    master_code: '',
    bank_branch: '',
    bank_account_number: '',
    postal_code: '',
    city: '',
    line1: '',
    id_card_type: '',
    id_card_number: '',
    corresponding_bank_swift: ''
  }

  public formOptions: Array<Array<FormItemsOption>> = []

  public formRules: Dictionary<Array<FormRule>> = this.individualRecipientUseCase.recipientFormRules

  receivableCountries: Array<{ label: string; value: string | number | boolean }> = []
  availableCurrencies: Dictionary<Array<string>> = {}

  recipientSkeletonFields: Array<RecipientSkeletonFields> = []

  /**
   * formRule의 required가 true인 formField 전체애 대해 값이 모두 채워졌는지 판단 여부를 나타낸다.
   * 
   * @type {boolean} 판단 여부. 모두 채워졌다면 true
   */
  get isEveryFieldFilled(): boolean {
    return this.formOptions.every(option => {
      return option.every(optionInside => {
        const constProp: keyof IndividualRecipientToRegister = optionInside.prop as keyof IndividualRecipientToRegister
        if (this.formRules[constProp] ? this.formRules[constProp][0].required !== true : true) return true
        if (this.formField.hasOwnProperty(constProp) && this.formField[constProp].toString().length > 0) return true
        else return false
      })
    })
  }

  /**
   * '수취 국가' 셀렉트에서 change 이벤트가 발생했을 때, formField의 master_code와 currency를 초기화하고 formOptions를 재설정한다.
   */
  onChangeNation(): void {
    this.formField.master_code = ''
    this.formField.currency = ''
    this.setOptions()
  }

  /**
   * '수취환' 셀렉트에서 change 이벤트가 발생했을 때, formOptions를 재설정한다.
   *  - 수취수단 추가로 인해 수취한 변경시 수취수단의 첫번째 값으로 수취수단 초기화
   *  - 수취수단 초기화에 따른 skeleton 필드 재설정
   */
  onChangeCurrency(): void {
    this.setOptions()
    this.formField.remittance_method_type = this.formOptions[2][0].options?.[0]?.value as keyof typeof ReceiveMethodEnum
    this.setFieldWithSkeleton(this.formField.country, this.formField.remittance_method_type)
  }

  /**
   * '수취인 타입' 라디오에서 change 이벤트가 발생했을 때, formOptions를 재설정한다.
   */
  onChangeIndividual(): void {
    this.setOptions()
  }

  /**
   * '수취 수단' 라디오에서 change 이벤트가 발생했을 때, formOptions를 재설정한다.
   */
  onChangeRemittanceMethod(): void {
    this.setOptions()
  }

  /**
   * 폼의 유효화를 판단한다.
   * 
   * @param formName 유효화를 판단할 폼의 ref
   * @returns {boolean} 유효화 판단 여부. 유효하면 true
   * 
   * @async
   */
  async formValidate(formName: any): Promise<boolean> {
    const isValid = await this.individualRecipientUseCase.formValidate(formName)
    return isValid
  }

  /**
   * formField를 수취인 등록 API의 파라미터 형태로 변환한다.
   * 
   * @returns {IRecipient} 변환한 파라미터
   */
  refineFieldToParam(): IRecipient {
    const masterCode = ReceiveMethodEnum.USD_ANYWHERE === ReceiveMethodEnum[this.formField.remittance_method_type] 
      ? usdAnyWhereMasterCodes[this.formField.country].toString()
      : this.formField.master_code

    let refinedParam: IRecipient = {
      corp_id: this.corpId,
      corp_pid: this.formField.corp_pid,
      country: this.formField.country,
      currency: this.formField.currency,
      first_name: this.formField.is_individual? `${this.formField.first_name}`: `${this.formField.corp_name}`,
      middle_name: this.formField.middle_name,
      last_name: this.formField.last_name,
      phone_code: Number(this.formField.phone_code),
      phone_number: this.formField.phone_number,
      remittance_method_type: ReceiveMethodEnum[this.formField.remittance_method_type],
      remittance_method_data: {
        bank_swift: this.formField.bank_swift,
        bank_iban: this.formField.bank_iban,
        bank_routing_number: this.formField.bank_routing_number,
        bank_sort_code: this.formField.bank_sort_code,
        bank_ifsc: this.formField.bank_ifsc,
        bank_transit_code: this.formField.bank_transit_code,
        bank_bsb_code: this.formField.bank_bsb_code,
        debit_card_number: this.formField.debit_card_number,
        bank_branch: this.formField.bank_branch,
        bank_account_number: this.formField.bank_account_number,
        corresponding_bank_swift: this.formField.corresponding_bank_swift
      },
      master_code: masterCode,
      postal_code: this.formField.postal_code,
      city: this.formField.city,
      line1: this.formField.line1,
      id_document_type: Number(this.formField.id_card_type),
      id_document_number: this.formField.id_card_number
    }

    for (const [key, value] of Object.entries(refinedParam)) {
      const constKey: keyof IRecipient = key as keyof IRecipient
      if (value === '') delete refinedParam[constKey]
    }

    return refinedParam
  }

  /**
   * formField를 파라미터 형태로 변환하여 수취인 등록 로직을 recipientUseCase에서 호출한다.
   * 
   * @returns {boolean} 등록 실패 여부. 등록에 실패하면 true
   * @async
   */
  async createRecipient(): Promise<boolean> {
    const createRecipientParam: CreateRecipientsParam = {
      list: [this.refineFieldToParam()]
    }
    const isFailed: boolean = await this.recipientUseCase.registerRecipients(createRecipientParam)
    return isFailed
  }

  /**
   * MasterCodes를 셀렉트 옵션의 형태로 변환한다.
   * @param {Dictionary<number>} masterCodes 변환할 masterCodes
   * @returns {Array<{ label: string; value: string | number | boolean }>} 변환된 셀렉트 옵션
   */
  convertMasterCodesToOptions(
    masterCodes: Dictionary<number>
  ): Array<{ label: string; value: string | number | boolean }> {
    let convertedMasterCodes: Array<{ label: string; value: string | number | boolean }> = []
    for (const [key, value] of Object.entries(masterCodes)) {
      convertedMasterCodes.push({ label: key, value: value.toString() })
    }
    convertedMasterCodes.sort((prev, next) => {
      if (prev.label > next.label) return 1
      else return -1
    })
    return convertedMasterCodes
  }

  /**
   * countries를 셀렉트 옵션의 형태로 변환한다.
   * 
   * @param {Array<{ iso: string; name: string }>} countries 변환할 countries
   * @returns {Array<{ label: string; value: string | number | boolean }>} 변환된 셀렉트 옵션
   */
  convertCountriesToOptions(
    countries: Array<{ iso: string; name: string }>
  ): Array<{ label: string; value: string | number | boolean }> {
    return countries.map(country => {
      return {
        label: country.name,
        value: country.iso
      }
    })
  }

  /**
   * IDCardTypeEnum의 배열을 셀렉트 옵션 형태로 변환한다.
   * 
   * @param {Array<IdCardTypeEnum>} idCardTypes 변환할 IDCardTypeEnum 배열
   * @returns {Array<{ label: string; value: string | number | boolean }>} 변환된 셀렉트 옵션
   */
  convertIdCardTypesToOptions(
    idCardTypes: Array<IdCardTypeEnum>
  ): Array<{ label: string; value: string | number | boolean }> {
    return idCardTypes.map(idCardType => {
      return {
        label: i18n.t(`sheet.id_types.${IdCardTypeEnum[idCardType].toLowerCase()}`) as string,
        value: `${idCardType}`
      }
    })
  }

  /**
   * UseCase로부터 receivableCountries를 가져와 receivableCountries에 셀렉트 옵션으로 변환하여 할당한다.
   * 
   * @async
   */
  async setReceivableCountries(): Promise<void> {
    const receivableCountries: Array<{ iso: string; name: string }> =
      await this.individualRecipientUseCase.getReceivableCountries()
    this.receivableCountries = this.convertCountriesToOptions(receivableCountries)
  }

  /**
   * UseCase로부터 receivableCurrencies를 가져와 availableCurrencies에 할당한다.
   * 
   * 사용자의 회사가 아날로그 송금 가능한 회사이며, receivableCurrencies에 USD가 포함돼있지 않을 경우 USD를 availableCurrencies에 푸시한다.
   * @async
   */
  async setAvailableCurrencies(): Promise<void> {
    const availableCurrencies: Dictionary<Array<string>> =
      await this.individualRecipientUseCase.getReceivableCurrencies()
    const isAnalogue: boolean = await this.recipientUseCase.isAnalogueCorp()
    for (const [key, value] of Object.entries(availableCurrencies)) {
      this.availableCurrencies[key] = value
      if (isAnalogue && !value.includes('USD')) this.availableCurrencies[key].push('USD')
    }
  }

  /**
   * 국가에 맞는 formOptions를 설정한다.
   * 
   * - 선택된 국가와 일치하는 skeleton이없고 선택된 국가가 있는경우 국가는 ZZ로 치환
   * - 수취환 formOption을 push 하는 방식으로 update되어 formOption 초기화 후 신규 필드 추가
   * 
   * @param {string} country 설정할 국가
   * @param {keyof typeof ReceiveMethodEnum} remittanceMethodType 수취 수단
   */
  setFieldWithSkeleton(country: string, remittanceMethodType: keyof typeof ReceiveMethodEnum): void {
    const receiveMethodCode = ReceiveMethodEnum[remittanceMethodType]
    const hasCountryInSkeletonFields = !!this.recipientSkeletonFields.filter( skeleton => skeleton.country === country).length
    
    if (!hasCountryInSkeletonFields && country !== '') country = 'ZZ'

    // 수취인 타입 이외의 필드 초기화
    this.formOptions[2] = [this.formOptions[2][0]]

    this.individualRecipientUseCase.formOptionsForSkeletonMap.forEach((value, key) => {
      this.recipientSkeletonFields.find( 
        skeleton => skeleton.country === country && skeleton.receive_method === receiveMethodCode
      )?.fields.forEach(field => {
        if (key === field.id ) this.formOptions[2].push(value)
      })
    })
  }

  /**
   * formOptions내 master_code를 찾아, 국가 또는 국가, 송금환에 맞는 masterCodes 옵션을 할당한다.
   * 
   * - formOptions[2](수취수단 정보의 formOption) 내에 master_code prop이 있을 경우에만 실행
   * - currencyDivision이 있는 국가에 대해서는 국가와 송금환, 그렇지 않은 국가에 대해서는 국가만으로 masterCodes를 가져온다.
   * - 가져온 masterCodes를 셀렉트 옵션 형태로 변환하여 formOptions[2] master_code 옵션에 할당한다.
   */
  setMasterCodesOptions(): void {
    const indexOfMasterCode: number = this.formOptions[2].findIndex(option => option.prop === 'master_code')
    if (indexOfMasterCode >= 0) {
      let getMasterCodesParam: IGetMasterCodeOption
      if (this.countryWithCurrencyDivision.includes(this.formField.country)) {
        getMasterCodesParam = { country: this.formField.country, receiveCurrency: this.formField.currency }
      } else {
        getMasterCodesParam = { country: this.formField.country }
      }
      const getMasterCodes: Dictionary<number> =
        this.individualRecipientUseCase.getMasterCodesWithCountry(getMasterCodesParam)
      const convertedMasterCodesOptions: Array<{ label: string; value: string | number | boolean }> =
        this.convertMasterCodesToOptions(getMasterCodes)
      this.formOptions[2][indexOfMasterCode].options = convertedMasterCodesOptions
    }
  }

  /**
   * formOptions내 id_card_type을 찾아 formField 국가의 IdCardTypes를 셀렉트 옵션으로 변환하여 formOptions[2](수취수단 정보의 formOption) id_card_type prop에 할당한다.
   * 
   * - formOptions[2] 내에 id_card_type이 있을 경우에만 실행
   */
  setIdCardTypeOptions(): void {
    const indexOfIdCardType: number = this.formOptions[2].findIndex(option => option.prop === 'id_card_type')
    if (indexOfIdCardType >= 0) {
      const convertedIdCardTypes: Array<{ label: string; value: string | number | boolean }> =
        this.convertIdCardTypesToOptions(IdCardTypesByCountry[this.formField.country])
      this.formOptions[2][indexOfIdCardType].options = convertedIdCardTypes
    }
  }


  /**
   * 수취국가, 수취환, skeleton에 따라 수취수단 option 반환
   * 
   *  - 수취국가, 수취환이 선택되지 않은경우 빈 배열 반환
   *  - 수취환이 USD로 선택된 경우 skeletonField에 존재하는 수취수단 만큼 옵션 노출 (중국의 경우 BANK 는 단건 등록 불가)
   *  - 수취환이 USD이나 일치하는 국가, 수단의 skeletonField가 없으면 은행 옵션만 노출
   *  - 그 외(수취환이 USD가 아닌 경우) 은행 옵션만 노출 
   *  - 동일한 순서로 노출될수 있도록 sort
   * @returns {{label: string, value: string}[]} 
   */
  getRemittanceMethodTypeOptions = (): { label: string; value: string }[] => {
    if(!this.formField.country || !this.formField.currency) return []
    let resultOptions: {label: string , value: keyof typeof ReceiveMethodEnum}[] = []

    if(this.formField.currency === 'USD'){
      resultOptions =  this.recipientSkeletonFields.filter( skeleton => skeleton.country === this.formField.country).reduce((pre, skeleton)=>{
        if(skeleton.receive_method === ReceiveMethodEnum.USD_ANYWHERE){
          pre.push({ label: 'USD ANYWHERE (SWIFT)', value: 'USD_ANYWHERE' })
        }
        else{
          !UnsupportedBankCountriesForUSD.includes(skeleton.country) && pre.push({ label: i18n.t('commons.bank') as string, value: 'BANK' }) 
        }
        return pre
      }, <{label: string , value: keyof typeof ReceiveMethodEnum}[]>[] )
      if(!resultOptions.length) resultOptions =[{ label: i18n.t('commons.bank') as string, value: 'BANK' }]
    }
    else{
      resultOptions= [{ label: i18n.t('commons.bank') as string, value: 'BANK' }]
    }

    resultOptions.sort((prev, next) => {
      if (prev.label > next.label) return 1
      else return -1
    })

    return resultOptions
  }

  /**
   * formOptions를 재설정한다.
   * 
   * - formOptions를 기본 값으로 재설정한다.
   * - setFieldWithSkeleton를 호출하여 국가에 맞는 formOptions로 재구성한다.
   * - setMasterCodesOptions, setIdCardTypeOptions를 호출하여 각각 master_code, id_card_type이 존재할 경우 그에 맞는 options를 설정한다.
   */
  setOptions(): void {
    let nameOption: Array<FormItemsOption>

    if (this.formField.is_individual) {
      nameOption = [
        {
          prop: 'first_name',
          label: i18n.t('commons.name') as string,
          type: 'text',
          placeHolder: i18n.t('commons.name') as string,
          value: undefined
        },
        {
          prop: 'middle_name',
          label: `${i18n.t('sheet.field.middle_name')} (${i18n.t('commons.optional')})`,
          type: 'text',
          placeHolder: `${i18n.t('sheet.field.middle_name')} (${i18n.t('commons.optional')})`,
          value: undefined
        },
        {
          prop: 'last_name',
          label: i18n.t('sheet.field.last_name') as string,
          type: 'text',
          placeHolder: i18n.t('sheet.field.last_name') as string,
          value: undefined
        }
      ]
    } else {
      nameOption = [
        {
          prop: 'corp_name',
          label: i18n.t('commons.business_name') as string,
          type: 'text',
          placeHolder: i18n.t('commons.business_name') as string,
          value: undefined
        }
      ]
    }
    this.formOptions[0] = [
      {
        prop: 'corp_pid',
        label: i18n.t('sheet.field.corps_id') as string,
        type: 'text',
        toolTipIcon: {
          imgSrc: 'btn-info.svg',
          content: i18n.t('tooltip.recipient_number') as string,
          placement: 'bottom-start'
        },
        placeHolder: i18n.t('sheet.field.corps_id') as string,
        value: undefined
      },
      {
        prop: 'country',
        label: i18n.t('commons.receive_country') as string,
        options: this.receivableCountries,
        type: 'select',
        filterable: true,
        emitHandlerName: 'onChangeNation',
        placeHolder: i18n.t('commons.select') as string,
        value: undefined
      },
      {
        prop: 'currency',
        label: i18n.t('commons.receive_currency') as string,
        type: 'select',
        options: this.availableCurrencies[this.formField.country]
          ? this.availableCurrencies[this.formField.country].map(currency => {
              return {
                label: currency,
                value: currency
              }
            })
          : [{ label: 'USD', value: 'USD' }],
        placeHolder: i18n.t('commons.select') as string,
        emitHandlerName: 'onChangeCurrency',
        value: undefined
      }
    ]
    this.formOptions[1] = [
      {
        prop: 'is_individual',
        label: i18n.t('commons.recipients_type') as string,
        type: 'radio',
        options: [
          { label: i18n.t('commons.personal') as string, value: true },
          { label: i18n.t('commons.business') as string, value: false }
        ],
        emitHandlerName: 'onChangeIndividual',
        value: undefined
      },
      ...nameOption,
      {
        prop: 'phone_code',
        type: 'select',
        filterable: true,
        options: this.phoneCodes,
        formItemClass: 'phone-code-select',
        value: undefined
      },
      {
        prop: 'phone_number',
        label: i18n.t('commons.phone_number') as string,
        type: 'text',
        formItemClass: 'phone-number-input',
        placeHolder: i18n.t('commons.phone_number') as string,
        value: undefined
      }
    ]
    this.formOptions[2] = [
      {
        prop: 'remittance_method_type',
        label: i18n.t('commons.receiving_method') as string,
        type: 'radio',
        options: this.getRemittanceMethodTypeOptions(),
        emitHandlerName: 'onChangeRemittanceMethod',
        value: undefined
      }
    ]
    this.formOptions = JSON.parse(JSON.stringify(this.formOptions))
    this.setFieldWithSkeleton(this.formField.country, this.formField.remittance_method_type)
    this.setMasterCodesOptions()
    this.setIdCardTypeOptions()
  }

  /**
   * UseCase의 init 함수를 실행하고 corpId와 recipientSkeletonField를 가져온다.
   * 
   * setReceivableCountries, setAvailableCurrencies 호출로 각각 receivableCountries, availableCurrencies를 설정하고 setOptions를 호출하여 formOptions를 재설정한다.
   * @async
   */
  async init(): Promise<void> {
    await this.individualRecipientUseCase.init()
    this.corpId = (await this.individualRecipientUseCase.getMemberInfo()).corp_id
    this.recipientSkeletonFields = await this.recipientUseCase.getRecipientSkeletonFields()
    await this.setReceivableCountries()
    await this.setAvailableCurrencies()
    this.setOptions()
  }
}
